<template>
  <v-app>
    <!-- <v-app-bar></v-app-bar>-->
    <v-container fluid class="pa-0">
      <!-- logo 图标 -->
      <div class="logo py-2 px-6">
        <v-row class="d-flex align-center" style="text-align:center">
          <v-img src="../assets/i/favicon.png" max-height="45" max-width="45" class="ml-2"></v-img>
          <span class="mr-3"><strong>{{ pageTitle }}</strong></span>
        </v-row>
      </div>
      <!-- 地图组件 -->
      <div id="map-container" style="min-height: 100vh"></div>
      <!-- 主页按钮 -->
      <div class="main-button">
        <v-btn @click="toHome()" small>
          <v-icon left>mdi-home</v-icon>
          <strong>主页</strong>
        </v-btn>
      </div>
      <!-- 热异常 数据信息 popup -->
      <div hidden>
        <v-card :id="thermalObject.id" ref="thermal-data-content" min-width="180px" class="px-4" :elevation="0">
          <v-card-text>
            <v-row>
              <strong>latitude:</strong>{{ thermalObject.latitude }}
            </v-row>
            <v-row>
              <strong>longitude:</strong>{{ thermalObject.longitude }}
            </v-row>
            <v-row>
              <strong>confidence:</strong>
              <v-chip small color="orange">{{
                thermalObject.confidence
              }}</v-chip>
            </v-row>
            <v-row>
              <strong>time:</strong><small>{{ thermalObject.time }}</small>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <!-- 热异常 切换显示按钮 -->
      <div class="opt-fire-btn">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="otp-btn-size" color="white" @click="
                thermalBtnAble = !thermalBtnAble;
                toggleShowThermalList();
                toggleShowInciwebData();
              " v-bind="attrs" v-on="on">
              <v-icon :style="{ color: thermalBtnAble ? '#e42a17b8' : '#1976d2' }">mdi-fire</v-icon>
            </v-btn>
          </template>
          <span>热异常</span>
        </v-tooltip>
      </div>
      <!-- 播放组件 -->
      <div class="play-btn px-6 py-1 d-flex align-center" v-if="thermalBtnAble || windBtnAble">
        <v-row dense class="d-flex align-center">
          <v-col cols="2" class="d-flex justify-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-if="!isPlayHistory">
                  <v-btn icon @click="playHistory()" v-bind="attrs" v-on="on">
                    <v-icon size="40">mdi-play</v-icon>
                  </v-btn>
                </div>
                <div v-else>
                  <v-progress-circular size="36" :indeterminate="true">
                    <v-btn icon @click="playHistory()" v-bind="attrs" v-on="on">
                      <v-icon size="40">mdi-pause</v-icon>
                    </v-btn>
                  </v-progress-circular>
                </div>
              </template>
              <span>{{ isPlayHistory ? "暂停" : "播放" }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="1" class="d-flex justify-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :disabled="frontDateAble" @click="getFrontDate()" v-bind="attrs" v-on="on">
                  <v-icon size="30">mdi-chevron-left</v-icon>
                </v-btn>
              </template>
              <span>前一天</span>
            </v-tooltip>
          </v-col>
          <v-col cols="6" class="d-flex justify-center">
            <v-row dense no-gutters class="d-flex align-center">
              <v-col cols="6">
                <span>{{ showDateString }}</span>
              </v-col>
              <v-col cols="6">
                <v-row dense no-gutters>
                  <v-col cols="12" class="ml-n4">
                    <v-btn icon @click="addTime()" :disabled="nextHourDisabled" small>
                      <v-icon>mdi-chevron-up</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <span class="ml-1">{{ showTimeString.split(":")[0] }} :
                      {{ showTimeString.split(":")[1] }}</span>
                  </v-col>
                  <v-col cols="12" class="ml-n4">
                    <v-btn icon @click="reduceTime()" :disabled="lastHourDisabled" small>
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1" class="d-flex justify-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :disabled="behindDateAble" @click="getBehindDate()" v-bind="attrs" v-on="on">
                  <v-icon size="34">mdi-chevron-right</v-icon>
                </v-btn>
              </template>
              <span>后一天</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2" class="d-flex justify-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :disabled="behindDateAble" @click="backtoLatestDate()" v-bind="attrs" v-on="on">
                  <v-icon size="40">mdi-skip-next</v-icon>
                </v-btn>
              </template>
              <span>最近一天</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </div>

      <div class="opt-wind-btn">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="otp-btn-size" color="white" @click="
                windBtnAble = !windBtnAble;
                toggleShowWindlList();
              " v-bind="attrs" v-on="on">
              <v-icon :style="{
                  color: windBtnAble ? '#e42a17b8' : '#1976d2'
                }">mdi-weather-windy</v-icon>
            </v-btn>
          </template>
          <span>风速</span>
        </v-tooltip>
      </div>

      <!-- 空气质量 切换显示按钮 -->
      <div class="opt-air-btn">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="otp-btn-size" color="white" @click="
                airBtnAble = !airBtnAble;
                toggleShowAirQualityParamDatas();
              " v-bind="attrs" v-on="on">
              <v-icon :style="{ color: airBtnAble ? '#e42a17b8' : '#1976d2' }">mdi-weather-cloudy</v-icon>
            </v-btn>
          </template>
          <span>空气质量</span>
        </v-tooltip>
      </div>
      <div class="air-legend-card" v-if="airBtnAble">
        <v-card max-width="410px">
          <!-- openaq数据源 图例 -->
          <v-card-subtitle class="py-1 d-flex align-center">
            <span class="solid-circle mr-2"></span>
            <strong>openaq空气质量数据参考图例</strong>
          </v-card-subtitle>
          <v-card-text>
            <v-chip v-for="(airQualityParamItem, index) of airQualityParamValues" :key="index"
              @click="getAirQualityParamType(airQualityParamItem)" class="mr-1"
              :color="airQualityParamItem.selected ? 'primary' : ''">
              {{ airQualityParamItem.name }}
            </v-chip>
            <ul class="color-scale mt-2">
              <li class="color-scale_item" v-for="(color, index) in openaqAirQualityColorScales" :key="index"
                :style="{ 'background-color': color, width: '11.1111%' }">
                <span class="color-scale_value" v-if="index == 0">{{ selectedAirQualityParamValues[index]
                  }}{{ selectedAirQualityParamUnit }}</span>
                <span class="color-scale_value"
                  v-else-if="index == openaqAirQualityColorScales.length - 1">{{ selectedAirQualityParamValues[index] + "+" }}</span>
                <span class="color-scale_value" v-else>{{
                  selectedAirQualityParamValues[index]
                }}</span>
              </li>
            </ul>
          </v-card-text>
          <!-- aqicn 空气质量指数数据源 图例 -->
          <v-card-subtitle class="pb-0 d-flex align-center">
            <span class="dashed-circle mr-2"></span>
            <strong>aqicn空气质量指数参考图例</strong>
            <v-btn color="primary" small text class="ml-2" @click="
                aqicnAirQualityIndexBtnAble = !aqicnAirQualityIndexBtnAble;
                toggleShowAqicnIndexDatas();
              ">{{ aqicnAirQualityIndexBtnAble ? "隐藏" : "显示" }}</v-btn>
          </v-card-subtitle>
          <v-card-text class="">
            <ul class="color-scale mt-2">
              <li class="color-scale_item" v-for="(color, index) in aqicnAirQualityColorScales" :key="index"
                :style="{ 'background-color': color, width: '16.6666%' }">
                <span class="color-scale_value"
                  v-if="index == aqicnAirQualityColorScales.length - 1">{{ aqicnAirQualityIndexValues[index].value + "+" }}</span>
                <span class="color-scale_value" v-else>{{
                  aqicnAirQualityIndexValues[index].value
                }}</span>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </div>
      <!-- openaq空气质量参数 数据信息 popup -->
      <div hidden>
        <v-card :id="'openaq' + airQualityParamObj.id" ref="openaq-air-content" max-width="400px" class="px-4"
          :elevation="0">
          <v-card-text>
            <v-row>
              <strong>latitude:</strong>{{ airQualityParamObj.latitude }}
            </v-row>
            <v-row>
              <strong>longitude:</strong>{{ airQualityParamObj.longitude }}
            </v-row>
            <v-row>
              <strong>location:</strong>{{ airQualityParamObj.location }}
            </v-row>
            <v-row> <strong>city:</strong>{{ airQualityParamObj.city }} </v-row>
            <v-row>
              <strong>value:</strong>
              <v-chip small :color="
                  getColorByAirQualityParamTypeValue(airQualityParamObj.value)
                ">{{ airQualityParamObj.value }}</v-chip>
            </v-row>
            <v-row>
              <strong>time:</strong><small>{{ airQualityParamObj.time }}</small>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <!-- 数据加载loading -->
      <div class="progress-circular" v-show="showDataLoading">
        <v-progress-circular :size="50" color="white" indeterminate></v-progress-circular>
      </div>
      <!-- aqicn空气质量指数 数据信息 popup -->
      <div hidden>
        <v-card :id="'aqicn' + aqicnAirQualityIndexObj.id" ref="aqicn-quality-content" max-width="600px" class=""
          :elevation="0">
          <v-card-subtitle class="py-1"><strong>{{
              aqicnAirQualityIndexObj.location
            }}</strong></v-card-subtitle>
          <v-card-text class="pb-0">
            <v-row class="pa-2" dense :style="{
                'background-color': getColorTextByAirQualityIndexValue(
                  aqicnAirQualityIndexObj.aqiIndex
                ).color
              }">
              <span style="font-size:24px" class="mr-2">{{
                aqicnAirQualityIndexObj.aqiIndex
              }}</span>
              <span class="">{{ aqicnAirQualityIndexObj.text }}</span>
            </v-row>
            <v-row dense class="my-1">
              <span>{{ aqicnAirQualityIndexObj.time }}</span>
            </v-row>
            <v-row dense no-gutters v-for="type of ['pm2.5', 'pm10', 'o3']" :key="type"
              class="d-flex justify-center align-center" :class="type == 'pm10' ? 'mt-4 mb-4' : ''">
              <v-col cols="1">
                <strong>{{ type }}</strong>
              </v-col>
              <v-col cols="11">
                <ul style="list-style: none">
                  <li v-for="(key, index) in aqicnAirQualityIndexObj[type]" :key="index" class="aqi-quality-index-li"
                    :style="{
                      width: 100 / aqicnAirQualityIndexObj[type].length + '%'
                    }">
                    <v-row dense class="d-flex justify-center">
                      <strong><small>{{ key.day.split("-")[2]
                          }}{{
                            getDateAbbreviation(key.day.split("-")[2])
                          }}</small></strong>
                    </v-row>
                    <v-row dense class="d-flex justify-center">
                      <div class="number-circle" :style="{
                          'background-color': getColorTextByAirQualityIndexValue(
                            key.avg
                          ).color
                        }">
                        {{ key.avg }}
                      </div>
                    </v-row>
                    <v-row dense class="d-flex justify-center"><small>{{ key.min }}</small></v-row>
                    <v-row dense class="d-flex justify-center"><small>{{ key.max }}</small></v-row>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <!-- 温室气体 切换显示按钮 -->
      <div class="opt-greenhouse-gas-btn">
        <v-menu offset-x left nudge-left="5" z-index="0" :close-on-click="false" :close-on-content-click="false">
          <template #activator="{ on: onMenu }">
            <v-tooltip top>
              <template #activator="{ on: onTooltip }">
                <v-btn class="otp-btn-size" color="white" v-on="{ ...onMenu, ...onTooltip }" @click="
                    greenhouseGasBtnAble = !greenhouseGasBtnAble;
                    toggleGreenhouseGasDatas();
                  ">
                  <v-icon :style="{
                      color: greenhouseGasBtnAble ? '#e42a17b8' : '#1976d2'
                    }">mdi-weather-hurricane</v-icon>
                </v-btn>
              </template>
              <span>温室气体</span>
            </v-tooltip>
          </template>
          <!-- 类型按钮 -->
          <v-btn-toggle mandatory style="flex-direction:column" class="py-1" dense v-model="greenhouseGasBtnsIndex"
            @change="getGreenhouseGasDatasByType">
            <v-tooltip left v-for="(btn, index) in greenhouseGasBtnGroups" :key="index">
              <template #activator="{ on: onTooltip }">
                <v-btn v-on="{ ...onTooltip }" :class="[index > 0 ? 'mt-1' : '']"
                  :disabled="index <= 1 && gasEmissionYear > 2016">
                  <strong v-if="index < greenhouseGasBtnGroups.length - 1">{{
                    btn.text
                  }}</strong>
                  <strong v-else><small>{{ btn.text }}</small></strong>
                </v-btn>
              </template>
              <span>{{ btn.info }}</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-menu>
      </div>
      <!-- co2 实时占比数据 popup -->
      <div hidden>
        <v-card :id="co2Obj.id" ref="co2-data-content" min-width="200px" class="px-4" :elevation="0">
          <v-card-text>
            <v-row dense>
              <strong>latitude:</strong>{{ co2Obj.latitude }}
            </v-row>
            <v-row dense>
              <strong>longitude:</strong>{{ co2Obj.longitude }}
            </v-row>
            <v-row dense> <strong>value:</strong>{{ co2Obj.value }} </v-row>
          </v-card-text>
        </v-card>
      </div>
      <!-- co2 实时占比数据 图例 -->
      <div class="co2-legend-card" v-show="
          greenhouseGasBtnAble &&
            greenhouseGasBtnsIndex == greenhouseGasBtnGroups.length - 1
        ">
        <v-card class="pa-3" max-height="184px" max-width="90px">
          <div class="co2-color-gradients"></div>
          <div style="font-size:10px">
            <div style="margin-top:-4px">max</div>
            <div style="margin-top:138px">min</div>
          </div>
          <div style="transform: rotate(90deg) translateX(-54%) translateY(100%);width:150px">
            CO₂ <span class="ml-2">[percentage]</span>
          </div>
        </v-card>
      </div>
      <!-- 温室气体 排放量数据 popup -->
      <div hidden>
        <v-card :id="greenhouseGasEmissionObj.isoCode" ref="greenhouse-gas-content" min-width="200px" class="px-2"
          :elevation="0">
          <v-card-text class="pa-1">
            <v-row dense>
              <strong>Country Name:</strong>{{ greenhouseGasEmissionObj.countryName }}
            </v-row>
            <v-row dense>
              <strong>Population:</strong>{{ greenhouseGasEmissionObj.population }}
            </v-row>
            <v-row dense v-if="greenhouseGasEmissionObj.gdp != -9999">
              <strong>GDP:</strong>{{
                (greenhouseGasEmissionObj.gdp / 1000000000).toFixed(2)
              }}(billion $)
            </v-row>
            <v-row dense v-if="greenhouseGasEmissionObj.zeroCarbonTarget">
              <strong>Zero Emission Target:</strong>{{ greenhouseGasEmissionObj.zeroCarbonTarget }}
            </v-row>
            <v-row dense>
              <strong>Total Emission:</strong>{{ greenhouseGasEmissionObj.totalEmission }}(Mt)
            </v-row>
            <v-row dense>
              <strong>Per Capital Emission:</strong>{{ greenhouseGasEmissionObj.perCapitaEmission }}(t)
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <!-- 温室气体 排放量数据 图例 -->
      <div class="co2-legend-card" v-show="
          greenhouseGasBtnAble &&
            greenhouseGasBtnsIndex < greenhouseGasBtnGroups.length - 1
        ">
        <v-card class="pa-3" :max-height="greenhouseGasBtnsIndex == 0 ? '204px' : '184px'" max-width="90px">
          <!-- <v-card-text class="pa-0"> -->
          <v-row dense no-gutters>
            <v-col cols="6">
              <div class="co2-emission-color"></div>
            </v-col>
            <v-col cols="6" class="ml-n3">
              <div style="font-size:10px;width:20px">
                <div style="margin-top:-4px">max</div>
                <div style="margin-top:136px">min</div>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="" v-if="greenhouseGasBtnsIndex == 0">
            <v-col cols="6">
              <div style="width:20px;height:15px;background-color:rgb(0,0,255);"></div>
            </v-col>
            <v-col cols="6" class="ml-n3">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <div style="width:20px;height:15px;margin-top:-2px;" v-bind="attrs" v-on="on">
                    0
                  </div>
                </template>
                <span>零排放</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <div :style="{
              transform:
                greenhouseGasBtnsIndex == 0
                  ? 'rotate(90deg) translateX(-80%) translateY(50%)'
                  : 'rotate(90deg) translateX(-64%) translateY(50%)',
              width: '145px'
            }">
            CO₂<span class="ml-1"> [million tonnes]</span>
          </div>
          <!-- </v-card-text> -->
        </v-card>
      </div>
      <!-- 温室气体 排放量数据 按时间显示 -->
      <div class="play-btn d-flex align-center" style="max-width:280px" v-if="
          greenhouseGasBtnAble &&
            greenhouseGasBtnsIndex < greenhouseGasBtnGroups.length - 1
        ">
        <v-row class="d-flex align-center px-4">
          <v-col cols="2" class="d-flex justify-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" :disabled="gasEmissionPreviousYearAble"
                  @click="getGasEmissionFirstYear()">
                  <v-icon size="40">mdi-skip-previous</v-icon>
                </v-btn>
              </template>
              <span>第一年</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2" class="d-flex justify-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" :disabled="gasEmissionPreviousYearAble"
                  @click="getGasEmissionPreviousYear()">
                  <v-icon size="30">mdi-chevron-left</v-icon>
                </v-btn>
              </template>
              <span>前一年</span>
            </v-tooltip>
          </v-col>
          <v-col cols="4" class="d-flex justify-center">
            <!-- <v-form ref="gasEmissionYearForm">
              <v-text-field v-model="gasEmissionYear" dense style="width:75px;font-size:22px" :rules="gasEmissionYearRules()" @keydown.enter="gasEmissionYearValidate()" ref="gasEmissionYearDom">
                <v-icon slot="append" size="16" style="line-height:2">mdi-pencil</v-icon>
              </v-text-field>
            </v-form> -->
            <div style="font-size:22px">{{ gasEmissionYear }}</div>
          </v-col>
          <v-col cols="2" class="d-flex justify-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" :disabled="gasEmissionAfterYearAble"
                  @click="getGasEmissionAfterYear()">
                  <v-icon size="30">mdi-chevron-right</v-icon>
                </v-btn>
              </template>
              <span>后一年</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2" class="d-flex justify-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" :disabled="gasEmissionAfterYearAble"
                  @click="getGasEmissionLatestYear()">
                  <v-icon size="40">mdi-skip-next</v-icon>
                </v-btn>
              </template>
              <span>最近一年</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </div>
      <!-- 关于 按钮 -->
      <div class="about-btn">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="white" @click="aboutBtnAble = !aboutBtnAble" v-bind="attrs" v-on="on">
              <v-icon>{{ aboutBtnAble ? "mdi-close" : "mdi-information-outline" }}
              </v-icon>
            </v-btn>
          </template>
          <span>关于</span>
        </v-tooltip>
      </div>
      <div class="about-card" v-if="aboutBtnAble">
        <v-card max-width="400" max-height="500" style="overflow-y:auto">
          <v-card-title>关于五洲遥感</v-card-title>
          <v-card-text>
            五洲遥感展示了地表风速预测动画，空气质量状况，温室气体排放量和野火数据。
          </v-card-text>
          <v-card-subtitle>数据来源</v-card-subtitle>
          <v-card-text>
            风数据我们使用的是NOAA GFS atmos
            data，它是一种天气预报模型。我们下载最新的更新周期下的数据，理想状况下展示近14天的数据。<br />
            空气质量数据来自openaq，空气指数数据来源于aqicn。
            温室气体排放数据集是由Our World in
            Data维护的关键指标集合，包括二氧化碳和甲烷排放数据。全球CO₂数据来自碳卫星拍摄的数据，我们取了每一度范围内的值做了平均。<br />
            热异常数据来自NASA Firms MODIS。美国西部真实火灾数据来源于inciweb。
          </v-card-text>
          <v-card-subtitle>免责声明</v-card-subtitle>
          <v-card-text>
            LANCE 系统由 NASA 的地球科学数据和信息系统 (ESDIS) 项目运营。通过
            LANCE、全球图像浏览服务 (GIBS)、Worldview 和 FIRMS
            提供的信息“按原样”提供，用户对其使用数据、任何业务或利润损失或任何间接责任承担全部责任和义务。
            、因使用或无法使用数据而导致的偶然或间接损害，即使 NASA 或 ESDIS
            之前已被告知可能发生此类损害，或者您或任何其他人提出任何其他索赔。
            ESDIS
            不作任何类型的明示或暗示的陈述或保证，包括对特定用途的适用性或适销性的暗示保证，或关于数据、其他信息数据库中的准确性或缺失或存在或缺陷或错误的保证.数据中使用的名称并不意味着
            ESDIS
            对任何国家、领土、城市或地区或其当局的法律或发展状况或其边界或边界的划分表达任何意见。有关更多信息，请联系
            Earthdata 支持。
          </v-card-text>
        </v-card>
      </div>
      <!-- inciweb 数据信息 popup -->
      <div hidden>
        <v-card :id="inciwebObj.id" ref="inciweb-data-content" min-width="200px" class="px-4" :elevation="0">
          <v-card-text>
            <v-row> <strong>name:</strong>{{ inciwebObj.name }} </v-row>
            <v-row> <strong>type:</strong>{{ inciwebObj.type }} </v-row>
            <v-row> <strong>state:</strong>{{ inciwebObj.state }} </v-row>
            <v-row> <strong>size:</strong>{{ inciwebObj.size }} </v-row>
            <v-row> <strong>latitude:</strong>{{ inciwebObj.latitude }} </v-row>
            <v-row>
              <strong>longitude:</strong>{{ inciwebObj.longitude }}
            </v-row>
            <v-row> <strong>updated:</strong>{{ inciwebObj.updated }} </v-row>
          </v-card-text>
        </v-card>
      </div>
      <!--MODIS_Terra_Land_Surface_Temp  图例 -->
      <div class="co2-legend-card" v-show="isShowLandTemp">
        <v-card class="pa-3" min-height="184px" max-width="90px">
          <div class="co2-color-gradients"></div>
          <div style="font-size:12px;float:left">
            <div style="margin-top:-2px">50℃</div>
            <div style="margin-top:130px">-50℃</div>
          </div>
        </v-card>
      </div>
      <v-snackbar v-model="showSnackbar" :color="snackbarColor" :timeout="2000" :top="true">
        {{ snackbarText }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>
<script>
import mapCfg from "../config/map";
import L from "leaflet"; //leaflet地图obj
import axios from "axios"; //基于Promise的HTTP客户端
//import qs from "qs";
import "leaflet-velocity";
import "leaflet-velocity/dist/leaflet-velocity.css";
import $ from "jquery";
import "leaflet.markercluster/dist/leaflet.markercluster-src";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import polylabel from "polylabel";
import commonCfg from "../config/common";
let commonUrl = commonCfg.commonUrl;
let version = commonCfg.urlVersion;

let vm;
let map, layerControl;
let currentThermalPopup = null,
  thermalMarkerClusters = null; //当前点击的热异常数据popup
let dateChangedCounter = 0,
  currentDate = new Date(); //日期变化计数器
let historyCount = 14,
  playWindHistoryInterval = null,
  playHistoryInterval = null; //历史纪录天数 播放热异常历史纪录的定时
let updatedAirQualityParamMarkers = [],
  currentUpdatedAirQualityParamPopup = null; //最近（两天）有更新的空气质量参数数据
let aqicnAirQualityIndexMarkers = [],
  aqicnAirQualityIndexPopup = null; //aqicn空气质量指数数据
let velocityLayer = null;
let co2DataMarkers = [],
  currentCO2DataPopup = null; //co2实时占比数据
let inciwebMarkers = [],
  inciwebDataPopup = null; //火灾真实事件
let greenhouseGasEmissionPolygons = [],
  greenhouseGasEmissionPopup = null; //温室气体排放量polygons数据
let greenhouseGasEmissionList = []; //温室气体排放量列表
let minGhGasEmissionYear = 1990,
  maxGhGasEmissionYear = 2020; //温室气体排放最大最小年
let countryCoordinates = []; //国家坐标数组
export default {
  data () {
    return {
      thermalAbnormalityList: [], //热异常数据列表
      thermalObject: {
        //popup 热异常数据对象
        id: 0, //id
        latitude: 0, //纬度
        longitude: 0, //经度wei
        confidence: 0, //知信度
        time: "" //时间
      },
      pageTitle: "五洲遥感",
      windData: [],
      windBtnAble: false, //风速数据切换按钮
      thermalBtnAble: false, //热异常数据切换按钮
      frontDateAble: false, //前一天时间是否可用按钮
      behindDateAble: true, //后一天时间是否可用按钮
      showDateString: "", //日期显示字符串
      showTimeString: "", //时间显示字符串
      isPlayHistory: false, // 是否播放热异常历史数据
      // isPlayWindHistory: false, // 是否播放风速历史数据
      airBtnAble: false, //空气质量数据切换按钮
      openaqAirQualityColorScales: [
        //openaq空气质量参数 颜色比例
        "rgb(11, 117, 169)",
        "rgb(33, 181, 187)",
        "rgb(143, 212, 217)",
        "rgb(191, 230, 236)",
        "rgb(227, 240, 217)",
        "rgb(246, 225, 158)",
        "rgb(250, 173, 91)",
        "rgb(243, 109, 60)",
        "rgb(217, 49, 39)"
      ],
      airQualityParamValues: [
        //openaq空气质量参数范围值
        {
          name: "pm2.5",
          values: [0, 12.2, 24.4, 36.7, 48.9, 61.1, 73.3, 85.6, 97.8],
          selected: true,
          unit: "µg/m³"
        },
        {
          name: "pm10",
          values: [0, 31, 61, 92, 122, 153, 193, 214, 244],
          selected: false,
          unit: "µg/m³"
        },
        {
          name: "NO₂",
          values: [0, 0.07, 0.14, 0.22, 0.29, 0.36, 0.43, 0.51, 0.58],
          selected: false,
          unit: "ppm"
        },
        {
          name: "CO",
          values: [0, 1.22, 2.44, 3.67, 4.89, 6.11, 7.33, 8.56, 9.78],
          selected: false,
          unit: "ppm"
        },
        {
          name: "SO₂",
          values: [0, 0.02, 0.05, 0.07, 0.1, 0.12, 0.15, 0.17, 0.2],
          selected: false,
          unit: "ppm"
        },
        {
          name: "O₃",
          values: [0, 0.02, 0.04, 0.06, 0.07, 0.09, 0.11, 0.13, 0.15],
          selected: false,
          unit: "ppm"
        },
        {
          name: "BC",
          values: [0, 0.33, 0.67, 1.0, 1.33, 1.67, 2.0, 2.33, 2.67],
          selected: false,
          unit: "µg/m³"
        }
      ],
      selectedAirQualityParamValues: [], //选中的空气质量参数范围值
      selectedAirQualityParamUnit: "µg/m³", //选中的空气质量参数单位
      airQualityParamObj: {
        //popup openaq空气质量参数对象
        id: "",
        location: "",
        city: "",
        latitude: "",
        longitude: "",
        value: "",
        time: ""
      },
      lastHourDisabled: false, // 是否禁用前3小时按钮
      nextHourDisabled: false, // 是否禁用后3小时按钮
      showDataLoading: false, //是否显示数据加载loading
      aqicnAirQualityColorScales: [
        //aqicn空气质量指数 颜色比例
        "#009966",
        "#ffde33",
        "#ff9933",
        "#cc0033",
        "#660099",
        "#7e0023"
      ],
      aqicnAirQualityIndexValues: [
        //aqicn空气质量指数 范围值
        { value: 0, text: "Good" },
        { value: 50, text: "Moderate" },
        { value: 100, text: "Unhealthy for Sensitive Groups" },
        { value: 150, text: "Unhealthy" },
        { value: 200, text: "Very Unhealthy" },
        { value: 300, text: "Hazardous" }
      ],
      aqicnAirQualityIndexObj: {
        //aqicn空气质量指数 popup
        id: "",
        location: "",
        aqiIndex: "",
        text: "",
        "pm2.5": [],
        pm10: [],
        o3: [],
        time: ""
      },
      // tips
      showSnackbar: "",
      snackbarColor: "",
      snackbarText: "",
      //co2BtnAble: false, //co2数据切换按钮
      co2Obj: {
        //co2 popup 数据
        id: "",
        latitude: "",
        longitude: "",
        value: ""
      },
      aboutBtnAble: false, //about 切换按钮
      inciwebObj: {
        //inciweb popup data
        id: "",
        name: "",
        type: "",
        state: "",
        updated: "",
        latitude: "",
        longitude: "",
        size: ""
      },
      //co2PercentageBtnAble: false,//是否点击了co2占空气比例的按钮
      //gasEmissionBtnAble: false,//
      gasEmissionYear: 1990, //气体排放量年份
      gasEmissionPreviousYearAble: true, //气体排放量前一年可用按钮
      gasEmissionAfterYearAble: false, //气体排放量后一年可用按钮
      greenhouseGasBtnGroups: [
        //温室气体按钮组
        {
          text: "总排放",
          column: "totalGreenhouseGasEmission",
          info: "温室气体总排放量"
        },
        { text: "CH₄", column: "methaneEmission", info: "甲烷总排放量" },
        {
          text: "P-CO₂",
          column: "productionCO2Emission",
          info: "基于生产的二氧化碳年排放量"
        },
        {
          text: "C-CO₂",
          column: "consumptionCO2Emission",
          info: "基于消费的二氧化碳年排放量"
        },
        { text: "CO₂比例", column: "", info: "二氧化碳占空气的百分比值" }
      ],
      greenhouseGasBtnsIndex: 0, //选中的温室气体按钮组下标
      greenhouseGasBtnAble: false, //是否点击了温室气体按钮
      greenhouseGasEmissionObj: {
        //温室气体排放对象
        isoCode: "",
        population: "",
        gdp: "",
        zeroCarbonTarget: "",
        countryName: "",
        totalEmission: "",
        perCapitaEmission: ""
      },
      showWindTime: [],
      aqicnAirQualityIndexBtnAble: true, //aqicn数据显示隐藏按钮
      isShowLandTemp: false //是否显示地表温度图例
    };
  },
  created () {
    vm = this;
    //设置当前时间
    this.showDateString = getShowDateString(currentDate);
    this.showTimeString = getShowTimeString(currentDate);
    console.log(this.showDateString);
    //设置空气质量参数范围值
    this.selectedAirQualityParamValues = this.airQualityParamValues[0].values;
  },
  async mounted () {
    //初始化地图
    this.initMap();

    //this.showWindLayer();
    this.showLandSurfaceLayer();
    this.showVolcanoLayer();
    this.showTrueColorLayer();
  },
  destroyed () {
    clearInterval(playHistoryInterval);
    playHistoryInterval = null;
  },
  methods: {
    toHome () {
      this.$router.push("/home");
    },
    //初始化地图
    initMap () {
      let mapOptions = {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        //attributionControl: false, //If true , an AttributionControl will be added to the map.
        maxZoom: mapCfg.maxZoom, //The maximum zoom level of the map (0-24).
        id: "mapbox/satellite-streets-v11", //	The ID of the style.
        tileSize: mapCfg.tileSize,
        zoomOffset: -1,
        accessToken: mapCfg.mapBoxAccessToken,
        minZoom: 2
      };
      let satelliteStreetsLayer = L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        mapOptions
      );

      map = L.map("map-container", {
        layers: [satelliteStreetsLayer],
        attributionControl: true,
        zoomControl: false
      });

      mapOptions.id = "mapbox/dark-v10";
      let navigationNightLayer = L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        mapOptions
      );

      mapOptions.id = "mapbox/light-v10";
      let navigationLightLayer = L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        mapOptions
      );

      layerControl = L.control.layers(
        {
          "Satellite streets": satelliteStreetsLayer,
          Dark: navigationNightLayer,
          Light: navigationLightLayer
        },
        null,
        {
          position: "topright"
          //collapsed: false
        }
      );
      layerControl.addTo(map);

      map.setView([50.0, 14.44], 3);

      //缩放控件
      L.control
        .zoom({
          position: "bottomright"
        })
        .addTo(map);
      //比例尺
      L.control
        .scale({
          imperial: false,
          position: "bottomright"
        })
        .addTo(map);
      $(
        '<h4 id="layers" style="margin-bottom:6px;margin-left:2px">Layers</h4>'
      ).insertBefore("div.leaflet-control-layers-base");

      $(
        '<h4 id="overlayers" style="margin-bottom:6px;margin-left:2px">Overlayers</h4>'
      ).insertAfter("div.leaflet-control-layers-separator");

      //监听layer添加事件
      map.on("layeradd", function (e) {
        if (e.layer.options.layer == "MODIS_Terra_Land_Surface_Temp_Day") {
          vm.isShowLandTemp = true;
        }
      });
      //监听layer移除事件
      map.on("layerremove", function (e) {
        if (e.layer.options.layer == "MODIS_Terra_Land_Surface_Temp_Day") {
          vm.isShowLandTemp = false;
        }
      });
    },

    // initDemoMap () {
    //   var Esri_WorldImagery = L.tileLayer(
    //     "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    //     {
    //       attribution:
    //         "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, " +
    //         "AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
    //     }
    //   );

    //   var Esri_DarkGreyCanvas = L.tileLayer(
    //     "http://{s}.sm.mapstack.stamen.com/" +
    //     "(toner-lite,$fff[difference],$fff[@23],$fff[hsl-saturation@20])/" +
    //     "{z}/{x}/{y}.png",
    //     {
    //       attribution:
    //         "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, " +
    //         "NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community"
    //     }
    //   );

    //   var baseLayers = {
    //     Satellite: Esri_WorldImagery,
    //     "Grey Canvas": Esri_DarkGreyCanvas
    //   };

    //   map = L.map("map-container", {
    //     layers: [Esri_WorldImagery],
    //     zoomControl: false,
    //     minZoom: 1
    //   });

    //   layerControl = L.control.layers(baseLayers, null, {
    //     position: "topleft"
    //     //collapsed: false
    //   });
    //   layerControl.addTo(map);

    //   map.setView([50.0, 14.44], 3);

    //   L.control
    //     .zoom({
    //       position: "bottomright"
    //     })
    //     .addTo(map);
    // },
    async getWindData (date, time) {
      this.showDataLoading = true;
      let res = await axios.get(
        `${commonUrl}listWindData${version}?day=${date}&time=${time}`
        // "http://192.168.1.109:8001/getFile"
      );
      // this.windData = res.data;
      if (res.data == null) {
        // this.tips("red", "暂无数据");
      }
      this.showDataLoading = false;
      return res.data;
    },
    //风
    async showWindLayer (date, time) {
      let wind_data = await this.getWindData(date, time);
      console.log(wind_data);
      if (wind_data == null) {
        if (velocityLayer != null) {
          map.removeLayer(velocityLayer);
          velocityLayer = null;
        }
        return;
      }
      if (velocityLayer == null) {
        this.initWindLayer(wind_data);
      } else {
        velocityLayer.setData(wind_data);
      }
      // console.log(this.velocityLayer);
    },
    //land surface temperature
    showLandSurfaceLayer () {
      var template =
        "//gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/" +
        "{layer}/default/{time}/{tileMatrixSet}/{z}/{y}/{x}.png";

      var layer = L.tileLayer(template, {
        layer: "MODIS_Terra_Land_Surface_Temp_Day",
        tileMatrixSet: "GoogleMapsCompatible_Level7",
        time: getPreviousDate(new Date())
      });

      layerControl.addOverlay(layer, "MODIS_Terra_Land_Surface_Temp_Day");
    },
    //火山
    showVolcanoLayer () {
      var template =
        "//gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/" +
        "{layer}/default/{time}/{tileMatrixSet}/{z}/{y}/{x}.png";

      var layer = L.tileLayer(template, {
        layer: "NDH_Volcano_Hazard_Frequency_Distribution_1979-2000",
        tileMatrixSet: "GoogleMapsCompatible_Level7",
        time: getPreviousDate(new Date())
      });

      layerControl.addOverlay(
        layer,
        "NDH_Volcano_Hazard_Frequency_Distribution_1979-2000"
      );
    },
    showTrueColorLayer () {
      var template =
        "//gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/" +
        "{layer}/default/{time}/{tileMatrixSet}/{z}/{y}/{x}.jpeg";

      var layer = L.tileLayer(template, {
        layer: "MODIS_Terra_CorrectedReflectance_TrueColor",
        tileMatrixSet: "GoogleMapsCompatible_Level9",
        time: getPreviousDate(new Date())
      });

      layerControl.addOverlay(
        layer,
        "MODIS_Terra_CorrectedReflectance_TrueColor"
      );
    },
    //获得热异常数据列表
    async getThermalAbnormalityList () {
      this.showDataLoading = true;
      console.log(this.showDateString);
      let res = await axios.get(
        `${commonUrl}getThermalAbnormalityList${version}`,
        {
          //'http://localhost:64011/getThermalAbnormalityList'
          params: {
            tableName: `firms${currentDate.getFullYear()}${this.showDateString.replaceAll(
              "-",
              ""
            )}`
          } //`firms${getPreviousDate(new Date()).replaceAll('-','')}`
        }
      );
      this.thermalAbnormalityList = res.data;
      this.showDataLoading = false;
    },
    //显示热异常marker
    async showThermalAnomalies () {
      await this.getThermalAbnormalityList();
      //let renderer = L.canvas();//{ padding: 0.01 }\
      if (thermalMarkerClusters) map.removeLayer(thermalMarkerClusters);
      if (currentThermalPopup) currentThermalPopup.remove();
      let thermalMarkers = [];
      for (let data of this.thermalAbnormalityList) {
        let marker = L.circleMarker([data.latitude, data.longitude], {
          //renderer: renderer,
          color: "#F88C34",
          weight: 0.8,
          opacity: 1,
          fill: true,
          fillColor: "#ED2A2A",
          fillOpacity: 1,
          radius: 6
        });

        thermalMarkers.push(marker);

        marker.on("click", function () {
          vm.thermalObject.id = data.id;
          vm.thermalObject.latitude = data.latitude;
          vm.thermalObject.longitude = data.longitude;
          vm.thermalObject.confidence = data.confidence;
          vm.thermalObject.time = `${data.timeText}`;
          let content = vm.$refs["thermal-data-content"].$el;
          currentThermalPopup = L.popup({ offset: [0, 0] })
            .setLatLng([data.latitude, data.longitude])
            .setContent(content);
          currentThermalPopup.openOn(map);
        });
      }
      //热异常数据marker集群
      thermalMarkerClusters = L.markerClusterGroup();
      thermalMarkerClusters.addLayers(thermalMarkers);
      map.addLayer(thermalMarkerClusters);
    },
    //切换显示热异常
    toggleShowThermalList () {
      if (this.thermalBtnAble) {
        currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1); //日期减1
        dateChangedCounter = 0;
        this.behindDateAble = true;
        this.showDateString = getShowDateString(currentDate);
        console.log(this.showDateString);
        // if (!thermalMarkerClusters) {
        //   //显示
        //   this.showThermalAnomalies();
        // } else {
        //   map.addLayer(thermalMarkerClusters);
        // }
        this.showThermalAnomalies();
      } else {
        //隐藏
        if (currentThermalPopup) currentThermalPopup.remove();
        map.removeLayer(thermalMarkerClusters);
        clearInterval(playHistoryInterval); //清除定时
      }
    },
    async showWind () {
      let date = `${currentDate.getFullYear()}${this.showDateString.replaceAll(
        "-",
        ""
      )}`;
      //In firefox,this will cause Invalid Date
      //let new_time = new Date(vm.showDateString + " " + vm.showTimeString);
      //let time = new_time.getHours() + "";
      let time = parseInt(vm.showTimeString.split(":")[0])
      await this.showWindLayer(date, time);
    },
    async getWindTime (day) {
      let res = await axios.get(
        `${commonUrl}listWindTime${version}?day=${day}`
        //"http://192.168.1.111:64011/listWindTime?day=" + day
      );
      this.showWindTime = res.data;
    },
    // 切换显示风速
    async toggleShowWindlList () {
      if (this.windBtnAble) {
        this.initWindLayer();
        let date = `${currentDate.getFullYear()}${this.showDateString.replaceAll(
          "-",
          ""
        )}`;
        await this.getWindTime(date);
        let hour = (this.showTimeString + "").split(":")[0];
        let index = this.showWindTime.indexOf(parseInt(hour) + "");
        if (index == -1) {
          if (this.showWindTime.length > 0) {
            let time = this.showWindTime[0];
            this.showTimeString = " " + (time < 10 ? "0" + time : time) + ":00";
          } else {
            await this.getFrontDate();
            dateChangedCounter = 0;
            this.behindDateAble = true;
          }
        }

        await this.showWind();
      } else {
        clearInterval(playWindHistoryInterval); //清除定时
        map.removeLayer(velocityLayer);
      }
    },
    //获得前一天日期
    async getFrontDate () {
      currentDate.setDate(currentDate.getDate() - 1); //日期减1
      let showTime = getShowDateString(currentDate);
      console.log(showTime);
      dateChangedCounter += 1; //计数器加1
      if (dateChangedCounter >= historyCount) {
        //默认展示 14天历史数据
        this.frontDateAble = true;
        let new_time = new Date(showTime + " " + vm.showTimeString);
        let hour = new_time.getHours();
        if (hour <= 3) {
          this.lastHourDisabled = true;
        }
      }
      this.nextHourDisabled = false;
      this.behindDateAble = false;
      if (this.windBtnAble) {
        let date = `${currentDate.getFullYear()}${showTime.replaceAll(
          "-",
          ""
        )}`;
        await this.getWindTime(date);

        let hour = (this.showTimeString + "").split(":")[0];
        let index = this.showWindTime.indexOf(parseInt(hour) + "");

        if (index == -1) {
          if (this.showWindTime.length > 0) {
            let time = this.showWindTime[0];
            this.showTimeString = " " + (time < 10 ? "0" + time : time) + ":00";
          } else {
            await this.getFrontDate();
            return;
          }
        }
        this.showDateString = showTime;
        this.showWind();
      }
      if (this.thermalBtnAble) {
        this.showDateString = showTime;
        this.showThermalAnomalies();
      }
      console.log(showTime);
    },
    //获得后一天日期
    async getBehindDate () {
      currentDate.setDate(currentDate.getDate() + 1); //日期加1

      let showTime = getShowDateString(currentDate);
      dateChangedCounter -= 1; //计数器减1
      if (dateChangedCounter == 0) {
        this.behindDateAble = true;
        // 在最后一天时 如果时间为21点则禁用nextTime按钮
        let new_time = new Date(showTime + " " + vm.showTimeString);
        let hour = new_time.getHours();
        if (hour == 21) {
          this.nextHourDisabled = true;
        }
      } else if (dateChangedCounter < 0) {
        this.backtoLatestDate();
        return;
      }
      this.lastHourDisabled = false;
      this.frontDateAble = false;
      if (this.windBtnAble) {
        let date = `${currentDate.getFullYear()}${showTime.replaceAll(
          "-",
          ""
        )}`;
        await this.getWindTime(date);
        let hour = (this.showTimeString + "").split(":")[0];
        let index = this.showWindTime.indexOf(parseInt(hour) + "");

        if (index == -1) {
          if (this.showWindTime.length > 0) {
            let time = this.showWindTime[0];
            this.showTimeString = " " + (time < 10 ? "0" + time : time) + ":00";
          } else {
            await this.getBehindDate();

            return;
          }
        }
        this.showDateString = showTime;
        this.showWind();
      }
      if (this.thermalBtnAble) {
        this.showDateString = showTime;
        this.showThermalAnomalies();
      }
    },
    //获得最近一天日期
    async backtoLatestDate () {
      currentDate = new Date(); //设最近一天
      currentDate.setDate(currentDate.getDate() - 1); //日期减1
      let showTime = getShowDateString(currentDate);
      dateChangedCounter = 0; //计数器重置
      this.behindDateAble = true;
      this.frontDateAble = false;
      if (this.windBtnAble) {
        let date = `${currentDate.getFullYear()}${showTime.replaceAll(
          "-",
          ""
        )}`;
        await this.getWindTime(date);
        let hour = (this.showTimeString + "").split(":")[0];
        let index = this.showWindTime.indexOf(parseInt(hour) + "");

        if (index == -1) {
          if (this.showWindTime.length > 0) {
            let time = this.showWindTime[0];
            this.showTimeString = " " + (time < 10 ? "0" + time : time) + ":00";
          } else {
            await this.getFrontDate();
            dateChangedCounter = 0;
            this.behindDateAble = true;
            return;
          }
        }
        this.showWind();
        this.showDateString = showTime;
      }
      if (this.thermalBtnAble) {
        this.showDateString = showTime;
        this.showThermalAnomalies();
      }
    },
    //初始化数据
    initHistory () {
      currentDate = new Date(); //设成最近的时间
      currentDate.setDate(currentDate.getDate() - historyCount);
      this.showDateString = getShowDateString(currentDate);
      dateChangedCounter = historyCount + 1;
      // 风速显示
      if (this.thermalBtnAble) {
        this.showThermalAnomalies();
      }
      if (this.windBtnAble) {
        this.showWind();
      }
    },
    //停止播放热异常数据
    stopPlayHistory () {
      clearInterval(playHistoryInterval); //清除定时
      playHistoryInterval = null;
      currentDate = new Date(); //重新设置时间
      this.showDateString = getShowDateString(currentDate);
      dateChangedCounter = 0; //计数器重置
      this.frontDateAble = false;
      this.isPlayHistory = false;
      this.playData();
    },
    playData () {
      if (this.thermalBtnAble) {
        this.showThermalAnomalies();
      }
      if (this.windBtnAble) {
        this.showWind();
      }
    },
    //播放热异常历史数据
    playHistory () {
      this.isPlayHistory = !this.isPlayHistory;
      if (this.isPlayHistory) {
        //播放
        this.behindDateAble = true;
        this.frontDateAble = true;
        this.initHistory();

        playHistoryInterval = setInterval(() => {
          //定时显示
          currentDate.setDate(currentDate.getDate() + 1);
          vm.showDateString = getShowDateString(currentDate);
          dateChangedCounter -= 1;
          this.playData();
          if (dateChangedCounter <= 1) {
            vm.stopPlayHistory();
          }
        }, 6000);
      } else {
        this.stopPlayHistory();
      }
    },
    async reduceTime () {
      if (this.windBtnAble) {
        console.log(this.showTimeString);
        let hour = await this.getLastHourV2(this.showTimeString);
        if (hour == null) {
          return;
        }
        console.log(hour);
        this.showTimeString = hour;
        await this.showWind();
      }
    },
    async addTime () {
      // this.nextHourDisabled = true;
      if (this.windBtnAble) {
        let hour = await this.getNextHourV2(this.showTimeString);
        if (hour == null) {
          return;
        }
        this.showTimeString = hour;
        console.log(this.showTimeString);
        await this.showWind();
      }
    },

    initWindLayer (data) {
      velocityLayer = L.velocityLayer({
        displayValues: true,
        displayOptions: {
          velocityType: "Wind - Global",
          position: "bottomleft",
          emptyString: "No water data"
        },
        data: data,
        maxVelocity: 15
      });
      // layerControl.addOverlay(vm.velocityLayer, "Wind - Global");
      velocityLayer.addTo(map);
    },

    //获得不同类型空气质量参数下对应的值列名和时间列名
    getColumnNameByAirQualityParamType (type) {
      let valueColumnName = "",
        timeColumnName = "";
      switch (type) {
        case this.airQualityParamValues[0].name: //pm2.5
          valueColumnName = "pm25_µg/m³";
          timeColumnName = "pm25_time";
          break;
        case this.airQualityParamValues[1].name: //pm10
          valueColumnName = "pm10_µg/m³";
          timeColumnName = "pm10_time";
          break;
        case this.airQualityParamValues[2].name: //NO2
          valueColumnName = "no2_ppm";
          timeColumnName = "no2_time";
          break;
        case this.airQualityParamValues[3].name: //CO
          valueColumnName = "co_ppm";
          timeColumnName = "co_time";
          break;
        case this.airQualityParamValues[4].name: //SO2
          valueColumnName = "so2_ppm";
          timeColumnName = "so2_time";
          break;
        case this.airQualityParamValues[5].name: //O3
          valueColumnName = "o3_ppm";
          timeColumnName = "o3_time";
          break;
        case this.airQualityParamValues[6].name: //BC
          valueColumnName = "bc_µg/m³";
          timeColumnName = "bc_time";
          break;
        default:
          valueColumnName = "";
          timeColumnName = "";
      }
      return {
        valueColumnName: valueColumnName,
        timeColumnName: timeColumnName
      };
    },
    //获得不同类型空气质量参数值对应的颜色
    getColorByAirQualityParamTypeValue (value) {
      let index = this.selectedAirQualityParamValues.length - 1;
      for (let i = 0; i < this.selectedAirQualityParamValues.length - 1; i++) {
        if (
          value >= this.selectedAirQualityParamValues[i] &&
          value < this.selectedAirQualityParamValues[i + 1]
        ) {
          //判断在这个范围内就赋值
          index = i;
          break;
        }
      }
      return this.openaqAirQualityColorScales[index];
    },
    //显示最近有更新的空气质量参数markers
    showUpdatedAirQualityParamMarkers (type, arr) {
      if (updatedAirQualityParamMarkers.length > 0) {
        for (let marker of updatedAirQualityParamMarkers) {
          marker.remove();
        }

        if (currentUpdatedAirQualityParamPopup)
          currentUpdatedAirQualityParamPopup.remove();

        updatedAirQualityParamMarkers = [];
        currentUpdatedAirQualityParamPopup = null;
      }
      let valueColumnName = this.getColumnNameByAirQualityParamType(type)
        .valueColumnName;
      let timeColumnName = this.getColumnNameByAirQualityParamType(type)
        .timeColumnName;

      for (let data of arr) {
        let marker = L.circleMarker([data.lat, data.lon], {
          color: "white",
          weight: 0.2,
          opacity: 1,
          fill: true,
          fillColor: this.getColorByAirQualityParamTypeValue(
            data[valueColumnName]
          ),
          fillOpacity: 1,
          radius: 7
        }).addTo(map);

        updatedAirQualityParamMarkers.push(marker);

        marker.on("click", function () {
          vm.airQualityParamObj.id = data.index;
          vm.airQualityParamObj.latitude = data.lat;
          vm.airQualityParamObj.longitude = data.lon;
          vm.airQualityParamObj.location = data.location;
          vm.airQualityParamObj.city = data.city;
          vm.airQualityParamObj.value = data[valueColumnName];
          vm.airQualityParamObj.time = data[timeColumnName];

          let content = vm.$refs["openaq-air-content"].$el;
          currentUpdatedAirQualityParamPopup = L.popup({
            offset: [0, 0],
            minWidth: 200
          })
            .setLatLng([data.lat, data.lon])
            .setContent(content);
          currentUpdatedAirQualityParamPopup.openOn(map);
        });
      }
    },
    //显示最近没有更新的空气质量参数markers
    showNoUpdatedAirQualityParamMarkers (type, arr) {
      console.log(type, arr);
    },
    //获得不同类型下空气质量参数 数据列表
    getAirAualityParamListByType (type) {
      //this.showDataLoading = true;
      axios
        .get(`${commonUrl}getAirQualityList${version}`, {
          //'http://localhost:64011/getAirQualityList'
          params: {
            time: new Date(
              new Date().getTime() - 2 * 24 * 3600 * 1000
            ).toISOString(), //两天前的UTC时间
            type: type
          }
        })
        .then(res => {
          vm.showUpdatedAirQualityParamMarkers(type, res.data.updated);
          //this.showDataLoading = false;
          //vm.showNoUpdatedAirQualityParamMarkers(type, res.data.noUpdated);
        })
        .catch(error => {
          //this.showDataLoading = false;
          console.log(error);
        });
    },
    //获得选中的空气质量参数
    getAirQualityParamType (item) {
      //console.log(item)
      //先把之前选中的设置成取消
      let index = this.airQualityParamValues.findIndex(
        item => item.selected == true
      );
      this.airQualityParamValues[index].selected = false;
      //再把空气质量参数范围值设置成当前选中的值
      item.selected = true;
      this.selectedAirQualityParamValues = item.values;
      this.selectedAirQualityParamUnit = item.unit;

      this.getAirAualityParamListByType(item.name);
    },
    //切换显示空气质量参数数据
    toggleShowAirQualityParamDatas () {
      if (this.airBtnAble) {
        //显示openaq空气质量数据
        if (
          updatedAirQualityParamMarkers.length <= 0
          //&& noUpdatedAirQualityParamMarkers.length <= 0
        ) {
          this.getAirAualityParamListByType(this.airQualityParamValues[0].name); //默认 pm2.5
        } else {
          //显示已更新和未更新的全部数据
          for (let marker of updatedAirQualityParamMarkers) {
            marker.addTo(map);
          }
        }
        //aqicn空气质量指数
        if (aqicnAirQualityIndexMarkers.length <= 0) {
          this.getAqicnAirQualityIndexList();
        } else {
          if (this.aqicnAirQualityIndexBtnAble)
            this.toggleShowAqicnIndexDatas();
        }
      } else {
        //隐藏已更新和未更新的opqnaq数据
        for (let marker of updatedAirQualityParamMarkers) {
          marker.remove();
        }
        if (currentUpdatedAirQualityParamPopup)
          currentUpdatedAirQualityParamPopup.remove();

        //隐藏aqicn空气质量指数数据
        if (this.aqicnAirQualityIndexBtnAble) {
          //隐藏aqicn空气质量指数数据
          for (let marker of aqicnAirQualityIndexMarkers) {
            marker.remove();
          }
          if (aqicnAirQualityIndexPopup) aqicnAirQualityIndexPopup.remove();
        }
      }
    },
    //切换显示aqicn空气指数数据
    toggleShowAqicnIndexDatas () {
      if (this.aqicnAirQualityIndexBtnAble) {
        for (let marker of aqicnAirQualityIndexMarkers) {
          marker.addTo(map);
        }
      } else {
        //隐藏aqicn空气质量指数数据
        for (let marker of aqicnAirQualityIndexMarkers) {
          marker.remove();
        }
        if (aqicnAirQualityIndexPopup) aqicnAirQualityIndexPopup.remove();
      }
    },
    //切换显示最近没有更新的空气质量参数数据
    toggleNoUpdatedAirQualityParamDatas () {
      // if (this.showNoUpdatedAirQualityParamBtn) {
      //   //显示
      //   for (let marker of noUpdatedAirQualityParamMarkers) {
      //     marker.addTo(map);
      //   }
      // } else {
      //   //隐藏
      //   for (let marker of noUpdatedAirQualityParamMarkers) {
      //     marker.remove();
      //   }
      //   if (currentNoUpdatedAirQualityParamPopup)
      //     currentNoUpdatedAirQualityParamPopup.remove();
      // }
    },
    //获得日期缩写
    getDateAbbreviation (date) {
      let abbr = "";
      switch (date) {
        case 1:
          abbr = "st";
          break;
        case 2:
          abbr = "nd";
          break;
        case 3:
          abbr = "rd";
          break;
        default:
          abbr = "th";
      }
      return abbr;
    },
    //获得aqi空气质量指数值对应的颜色和含义
    getColorTextByAirQualityIndexValue (value) {
      let index = this.aqicnAirQualityIndexValues.length - 1;
      for (let i = 0; i < this.aqicnAirQualityIndexValues.length - 1; i++) {
        if (
          value >= this.aqicnAirQualityIndexValues[i].value &&
          value < this.aqicnAirQualityIndexValues[i + 1].value
        ) {
          //判断在这个范围内就赋值
          index = i;
          break;
        }
      }
      return {
        color: this.aqicnAirQualityColorScales[index],
        text: this.aqicnAirQualityIndexValues[index].text
      };
    },
    //aqicn空气质量指数对应颜色的divIcon
    // getAqicnAirQualityDivIconByColor(){
    //   for(let color of this.aqicnAirQualityColorScales){
    //     let markerHtmlStyles = `
    //     background-color: ${color};
    //     width: 1rem;
    //     height: 1rem;
    //     display: block;
    //     left: -1.5rem;
    //     top: -1.5rem;
    //     position: relative;
    //     border-radius: 3rem 3rem 0;
    //     transform: rotate(45deg);
    //     border: 1px solid #FFFFFF`
    //     let icon = L.divIcon({
    //       className: markerHtmlStyles,
    //       iconAnchor: [-16, -16],//popup 左右 上下
    //       labelAnchor: [0, 0],
    //       popupAnchor: [0, 0],
    //       html: `<span style="${markerHtmlStyles}" />`
    //     })
    //     aqicnAirQualityIndexDivIcon[color] = icon
    //   }
    // },
    //显示aqicn空气质量指数markers
    showAqiAirQualityIndexMarkers (arr) {
      for (let data of arr) {
        let color = this.getColorTextByAirQualityIndexValue(
          parseInt(data.aqiIndex)
        ).color;
        let marker = L.circleMarker([data.latitude, data.longitude], {
          color: "white", //Stroke的color
          weight: 1.5, //Stroke的线宽
          //dashOffset:"3",
          dashArray: "4",
          opacity: 1,
          fill: true,
          fillColor: color,
          fillOpacity: 0.9,
          radius: 8
        }).addTo(map);

        aqicnAirQualityIndexMarkers.push(marker);

        let forecastJson = JSON.parse(data.forecastJson).daily;

        marker.on("click", function () {
          vm.aqicnAirQualityIndexObj.id = data.id;
          vm.aqicnAirQualityIndexObj.location = data.stationName;
          vm.aqicnAirQualityIndexObj.aqiIndex = parseInt(data.aqiIndex);
          vm.aqicnAirQualityIndexObj.text = vm.getColorTextByAirQualityIndexValue(
            parseInt(data.aqiIndex)
          ).text;
          vm.aqicnAirQualityIndexObj["pm2.5"] = forecastJson.pm25;
          vm.aqicnAirQualityIndexObj.pm10 = forecastJson.pm10;
          vm.aqicnAirQualityIndexObj.o3 = forecastJson.o3;
          vm.aqicnAirQualityIndexObj.time = data.dataTime;

          let content = vm.$refs["aqicn-quality-content"].$el;
          aqicnAirQualityIndexPopup = L.popup({ offset: [0, 0], minWidth: 360 })
            .setLatLng([data.latitude, data.longitude])
            .setContent(content);
          aqicnAirQualityIndexPopup.openOn(map);
        });
      }
    },
    //获得aqi空气质量指标 数据列表
    async getAqicnAirQualityIndexList () {
      this.showDataLoading = true;
      axios
        .get(`${commonUrl}getAqicnAirQualityList${version}`)
        .then(res => {
          vm.showAqiAirQualityIndexMarkers(res.data);
          this.showDataLoading = false;
        })
        .catch(error => {
          this.showDataLoading = false;
          console.log(error);
        });
    },
    tips (color, text) {
      this.showSnackbar = true;
      this.snackbarColor = color;
      this.snackbarText = text;
    },
    //显示co2实时占比 markers
    showCO2Markers (arr) {
      for (let data of arr) {
        let rgb = getRGBFromRibbon(
          data.value,
          arr[0].value,
          arr[arr.length - 1].value
        );

        let marker = L.circleMarker([data.latitude, data.longitude], {
          color: "black", //Stroke的color
          weight: 0.2, //Stroke的线宽
          //dashOffset:"3",
          //dashArray: '4',
          opacity: 1,
          fill: true,
          fillColor: `rgb(${rgb.red},${rgb.green},${rgb.blue})`,
          fillOpacity: 1,
          radius: 5
        }).addTo(map);

        co2DataMarkers.push(marker);

        marker.on("click", function () {
          vm.co2Obj.id = data.id;
          vm.co2Obj.latitude = data.latitude;
          vm.co2Obj.longitude = data.longitude;
          vm.co2Obj.value = data.value;

          let content = vm.$refs["co2-data-content"].$el;
          currentCO2DataPopup = L.popup({ offset: [0, 2], minWidth: 200 })
            .setLatLng([data.latitude, data.longitude])
            .setContent(content);
          currentCO2DataPopup.openOn(map);
        });
      }
    },
    //获得co2实时占比数据列表
    getCO2List () {
      this.showDataLoading = true;
      axios
        .get(`${commonUrl}getCO2List${version}`) //${commonUrl}getCO2List${version}
        .then(res => {
          let data = res.data,
            filterData = [];
          //console.log(data)
          //数据过滤
          let dv =
            data[Math.round(data.length * 0.95)].value -
            data[Math.round(data.length * 0.05)].value;
          for (let i = 0; i < data.length - 1; i++) {
            if (data[i + 1].value - data[i].value < dv * 0.05) {
              filterData.push(data[i + 1]);
            }
          }
          vm.showCO2Markers(filterData);
          this.showDataLoading = false;
        })
        .catch(error => {
          this.showDataLoading = false;
          console.log(error);
        });
    },
    //气体排放量年份输入规则
    // gasEmissionYearRules(){
    //    return [
    //     v => !!v || '必填',
    //     v => /^\d{4}$/g.test(v) || '4位数字',
    //     v => v >= minGhGasEmissionYear && v <= maxGhGasEmissionYear || `${minGhGasEmissionYear}~${maxGhGasEmissionYear}`,
    //   ];
    // },
    //气体排放量年份验证
    // gasEmissionYearValidate(){
    //   if(!this.$refs.gasEmissionYearForm.validate()) return
    //   //让输入框失去焦点
    //   this.$refs.gasEmissionYearDom.blur()
    //   this.gasEmissionYear = parseInt(this.gasEmissionYear)
    //   if(this.gasEmissionYear <= minGhGasEmissionYear){
    //     this.gasEmissionPreviousYearAble = true
    //     this.gasEmissionAfterYearAble = false
    //   } else if(this.gasEmissionYear >= maxGhGasEmissionYear) {
    //     this.gasEmissionAfterYearAble = true
    //     this.gasEmissionPreviousYearAble = false
    //   }else{
    //     this.gasEmissionPreviousYearAble = false
    //     this.gasEmissionAfterYearAble = false
    //   }
    //   this.getGreenhouseGasEmissionList()
    // },
    //获取气体排放量前一年年份
    getGasEmissionPreviousYear () {
      this.gasEmissionYear -= 1;
      if (this.gasEmissionYear <= minGhGasEmissionYear)
        this.gasEmissionPreviousYearAble = true;
      if (this.gasEmissionYear < maxGhGasEmissionYear)
        this.gasEmissionAfterYearAble = false;
      this.getGreenhouseGasEmissionList();
    },
    //获取气体排放量后一年年份
    getGasEmissionAfterYear () {
      this.gasEmissionYear += 1;
      if (this.gasEmissionYear > minGhGasEmissionYear)
        this.gasEmissionPreviousYearAble = false;
      if (this.gasEmissionYear >= maxGhGasEmissionYear)
        this.gasEmissionAfterYearAble = true;
      this.getGreenhouseGasEmissionList();
    },
    //获取气体排放量最近年份
    getGasEmissionLatestYear () {
      this.gasEmissionYear = maxGhGasEmissionYear;
      if (this.getGasEmissionPreviousYear)
        this.gasEmissionPreviousYearAble = false;
      this.gasEmissionAfterYearAble = true;
      this.getGreenhouseGasEmissionList();
    },
    //获取气体排放量首年年份
    getGasEmissionFirstYear () {
      this.gasEmissionYear = minGhGasEmissionYear;
      if (this.gasEmissionAfterYearAble) this.gasEmissionAfterYearAble = false;
      this.gasEmissionPreviousYearAble = true;
      this.getGreenhouseGasEmissionList();
    },
    //显示温室气体排放量颜色区域
    async showGreenhouseGasEmissionPolygons (arr) {
      if (greenhouseGasEmissionPolygons.length > 0) {
        this.hideGreenhouseGasEmissionPolygons();
        greenhouseGasEmissionPolygons = [];
      }
      let columnName = this.greenhouseGasBtnGroups[this.greenhouseGasBtnsIndex]
        .column;
      //数组过滤 过滤掉没有数据的
      let filterDatas = []; //过滤后的数组
      for (let data of arr) {
        if (data[columnName] == -9999) {
          //-9999代表无数据
          continue;
        }
        filterDatas.push(data);
      }
      //console.log(filterDatas)
      if (filterDatas.length <= 0) return;
      //找出最大最小值
      let sortDatas = []; //要排序的值放进数组
      for (let data of filterDatas) {
        sortDatas.push(data[columnName]);
      }
      //console.log(sortDatas)
      sortDatas.sort(function (a, b) {
        return a - b;
      }); //从小到大排序
      let minVal = sortDatas[0];
      let maxVal = sortDatas[sortDatas.length - 1];
      //console.log(minVal, maxVal)
      if (countryCoordinates.length <= 0) {
        await this.getCountryCoordinates();
      }
      for (let country of countryCoordinates) {
        let index = filterDatas.findIndex(
          data => data.isoCode == country.iso_a3
        );
        if (index <= -1) {
          continue;
        }
        let latlngs = JSON.parse(country.geometry_coord);
        let data = filterDatas[index];
        let rgb = getGasEmissionColor(data[columnName], minVal, maxVal);
        let polygon = L.polygon(latlngs, {
          color: "black",
          weight: 0.2,
          opacity: 1,
          fill: true,
          fillColor: `rgb(${rgb.red},${rgb.green},${rgb.blue})`,
          fillOpacity: 1
        }).addTo(map);

        polygon.on("click", function () {
          vm.greenhouseGasEmissionObj.isoCode = data.isoCode;
          vm.greenhouseGasEmissionObj.countryName = data.countryName;
          vm.greenhouseGasEmissionObj.population = data.population;
          vm.greenhouseGasEmissionObj.gdp = data.gdp;
          vm.greenhouseGasEmissionObj.zeroCarbonTarget = data.zeroCarbonTarget;
          if (vm.greenhouseGasBtnsIndex == 0) {
            vm.greenhouseGasEmissionObj.totalEmission =
              data.totalGreenhouseGasEmission;
            vm.greenhouseGasEmissionObj.perCapitaEmission =
              data.perCapitaGreenhouseGasEmission;
          } else if (vm.greenhouseGasBtnsIndex == 1) {
            vm.greenhouseGasEmissionObj.totalEmission = data.methaneEmission;
            vm.greenhouseGasEmissionObj.perCapitaEmission =
              data.perCapitaMethaneEmission;
          } else if (vm.greenhouseGasBtnsIndex == 2) {
            vm.greenhouseGasEmissionObj.totalEmission =
              data.productionCO2Emission;
            vm.greenhouseGasEmissionObj.perCapitaEmission =
              data.perCapitaProductionCO2;
          } else if (vm.greenhouseGasBtnsIndex == 3) {
            vm.greenhouseGasEmissionObj.totalEmission =
              data.consumptionCO2Emission;
            vm.greenhouseGasEmissionObj.perCapitaEmission =
              data.perCapitaConsumptionCO2;
          }

          //map.fitBounds(this.getBounds());
          let polygonCenter;
          if (country.geometry_type == "MultiPolygon") {
            //找出坐标数组长度最大的polygon
            let maxIndex = 0;
            for (let i = 0; i < latlngs.length; i++) {
              if (latlngs[i][0].length > latlngs[maxIndex][0].length)
                maxIndex = i;
            }
            let p = polylabel(latlngs[maxIndex], 1.0); //获得多边形中心
            polygonCenter = [p[0], p[1]];
          } else {
            polygonCenter = this.getBounds().getCenter();
          }
          let content = vm.$refs["greenhouse-gas-content"].$el;
          greenhouseGasEmissionPopup = L.popup({ minWidth: 300 })
            .setLatLng(polygonCenter)
            .setContent(content);
          greenhouseGasEmissionPopup.openOn(map);
        });

        greenhouseGasEmissionPolygons.push(polygon);
      }
    },
    async getCountryCoordinates () {
      this.showDataLoading = true;
      let res = await axios.get(`${commonUrl}getCountryCoordinates${version}`);
      countryCoordinates = res.data;
      this.showDataLoading = false;
    },
    //获得气体排放量数据列表
    getGreenhouseGasEmissionList () {
      this.showDataLoading = true;
      axios
        .get(`${commonUrl}getCountryGreenhouseGasByYear${version}`, {
          params: { year: this.gasEmissionYear }
        }) //${commonUrl}getCountryGreenhouseGasByYear${version}
        .then(res => {
          //console.log(res.data)
          this.showDataLoading = false;
          greenhouseGasEmissionList = res.data;
          if (res.data.length > 0)
            vm.showGreenhouseGasEmissionPolygons(res.data);
        })
        .catch(error => {
          this.showDataLoading = false;
          console.log(error);
        });
    },
    //隐藏温室气体排放区域polygons
    hideGreenhouseGasEmissionPolygons () {
      for (let polygon of greenhouseGasEmissionPolygons) {
        polygon.remove();
      }
      if (greenhouseGasEmissionPopup) {
        greenhouseGasEmissionPopup.remove();
        greenhouseGasEmissionPopup = null;
      }
    },
    //隐藏CO2实时占比markers
    hideCO2DataMarkers () {
      for (let marker of co2DataMarkers) {
        marker.remove();
      }
      if (currentCO2DataPopup) {
        currentCO2DataPopup.remove();
        currentCO2DataPopup = null;
      }
    },
    //切换温室气体数据
    toggleGreenhouseGasDatas () {
      if (this.greenhouseGasBtnAble) {
        //显示
        if (
          this.greenhouseGasBtnsIndex <
          this.greenhouseGasBtnGroups.length - 1
        ) {
          //显示排放数据
          if (greenhouseGasEmissionPolygons.length <= 0) {
            this.getGreenhouseGasEmissionList();
          } else {
            for (let polygon of greenhouseGasEmissionPolygons) {
              polygon.addTo(map);
            }
          }
        } else {
          //显示co2实时占比数据
          for (let marker of co2DataMarkers) {
            marker.addTo(map);
          }
        }
      } else {
        //隐藏
        if (
          this.greenhouseGasBtnsIndex <
          this.greenhouseGasBtnGroups.length - 1
        ) {
          this.hideGreenhouseGasEmissionPolygons();
        } else {
          this.hideCO2DataMarkers();
        }
      }
    },
    //点击获取不同类型的温室气体数据
    getGreenhouseGasDatasByType () {
      //console.log(this.greenhouseGasBtnsIndex)
      if (
        this.greenhouseGasBtnsIndex <
        this.greenhouseGasBtnGroups.length - 1
      ) {
        //选中气体排放数据
        if (co2DataMarkers.length > 0) {
          this.hideCO2DataMarkers();
        }
        this.showGreenhouseGasEmissionPolygons(greenhouseGasEmissionList);
      } else {
        //选中co2实时占比数据
        this.hideGreenhouseGasEmissionPolygons();
        if (co2DataMarkers.length <= 0) {
          this.getCO2List();
        } else {
          for (let marker of co2DataMarkers) {
            marker.addTo(map);
          }
        }
      }
    },

    //显示inciweb markers
    showInciwebMarkers (arr) {
      let fireIcon = L.icon({
        iconUrl: require("../assets/scenes/fire-icon.png"),
        iconSize: [12, 15] // size of the icon
      });
      for (let data of arr) {
        let marker = L.marker([data.lat, data.lng], { icon: fireIcon }).addTo(
          map
        );
        inciwebMarkers.push(marker);

        marker.on("click", function () {
          vm.inciwebObj.id = data.id;
          vm.inciwebObj.name = data.name;
          vm.inciwebObj.type = data.type;
          vm.inciwebObj.state = data.state;
          vm.inciwebObj.updated = data.updated;
          vm.inciwebObj.latitude = data.lat;
          vm.inciwebObj.longitude = data.lng;
          vm.inciwebObj.size = data.size;

          let content = vm.$refs["inciweb-data-content"].$el;
          inciwebDataPopup = L.popup({ offset: [0, 0], minWidth: 300 })
            .setLatLng([data.lat, data.lng])
            .setContent(content);
          inciwebDataPopup.openOn(map);
        });
      }
    },
    //获得inciweb 火灾真实事件数据
    getInciwebList () {
      axios
        .get(`${commonUrl}getInciwebData${version}`)
        .then(res => {
          //console.log(res.data)
          if (!res || !res.data) return;
          if (res.data.markers) vm.showInciwebMarkers(res.data.markers);
        })
        .catch(error => {
          console.log(error);
        });
    },
    //切换显示inciweb数据
    toggleShowInciwebData () {
      if (this.thermalBtnAble) {
        //显示
        if (inciwebMarkers.length <= 0) {
          this.getInciwebList();
        } else {
          for (let marker of inciwebMarkers) {
            marker.addTo(map);
          }
        }
      } else {
        //隐藏
        for (let marker of inciwebMarkers) {
          marker.remove();
        }
        if (inciwebDataPopup) inciwebDataPopup.remove();
      }
    },
    async getNextHourV2 (time) {
      let new_time = new Date(
        currentDate.getFullYear() + "-" + vm.showDateString + "" + time
      );

      let hour = (time + "").split(":")[0];
      let index = this.showWindTime.indexOf(parseInt(hour) + "");
      let lastTime = "";
      if (vm.behindDateAble && index == this.showWindTime.length - 2) {
        vm.nextHourDisabled = true;
      }
      if (index == this.showWindTime.length - 1) {
        console.log(index);
        new_time.setDate(new_time.getDate() + 1);

        dateChangedCounter -= 1; //计数器加1
        console.log(dateChangedCounter);
        if (dateChangedCounter <= 0) {
          vm.behindDateAble = true;
        }
        vm.frontDateAble = false;
        currentDate = new_time;
        vm.showDateString = getShowDateString(new_time);
        let date = `${currentDate.getFullYear()}${this.showDateString.replaceAll(
          "-",
          ""
        )}`;
        await this.getWindTime(date);
        if (this.showWindTime.length > 0) {
          lastTime = this.showWindTime[0];
        } else {
          await this.getBehindDate();
          return;
        }
      } else {
        lastTime = this.showWindTime[index + 1];
      }
      lastTime = parseInt(lastTime);
      vm.lastHourDisabled = false;
      return " " + (lastTime < 10 ? "0" + lastTime : lastTime) + ":00";
    },
    async getLastHourV2 (time) {
      let new_time = new Date(
        currentDate.getFullYear() + "-" + vm.showDateString + "" + time
      );

      let hour = (time + "").split(":")[0];
      let index = this.showWindTime.indexOf(parseInt(hour) + "");
      let lastTime = "";
      if (vm.frontDateAble && index == 1) {
        vm.lastHourDisabled = true;
      }
      if (index == 0) {
        new_time.setDate(new_time.getDate() - 1);
        dateChangedCounter += 1; //计数器加1
        console.log(dateChangedCounter);
        if (dateChangedCounter >= historyCount) {
          //默认展示 14天历史数据
          vm.frontDateAble = true;
        }
        vm.behindDateAble = false;

        currentDate = new_time;
        vm.showDateString = getShowDateString(new_time);
        let date = `${currentDate.getFullYear()}${this.showDateString.replaceAll(
          "-",
          ""
        )}`;
        await this.getWindTime(date);
        if (this.showWindTime.length > 0) {
          lastTime = this.showWindTime[this.showWindTime.length - 1];
        } else {
          await this.getFrontDate();
          return;
        }
      } else {
        lastTime = this.showWindTime[index - 1];
      }
      vm.nextHourDisabled = false;
      lastTime = parseInt(lastTime);
      return " " + (lastTime < 10 ? "0" + lastTime : lastTime) + ":00";
    }
  }
};
function getPreviousDate (date) {
  date.setDate(date.getDate() - 1);
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return Y + M + D;
}
function getShowDateString (date) {
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return M + D;
}
function getShowTimeString (date) {
  let time = date.getHours() - (date.getHours() % 3);
  var h = " " + (time < 10 ? "0" + time : time) + ":00";
  return h;
}

// function getLastHour (time) {
//   let new_time = new Date(
//     currentDate.getFullYear() + "-" + vm.showDateString + "" + time
//   );
//   let hour = new_time.getHours() - 3;
//   if (vm.frontDateAble && hour <= 3) {
//     vm.lastHourDisabled = true;
//   }
//   if (hour < 0) {
//     hour = 24 + hour;
//     new_time.setDate(new_time.getDate() - 1); //日期减1
//     dateChangedCounter += 1; //计数器加1
//     if (dateChangedCounter >= historyCount) {
//       //默认展示 14天历史数据
//       vm.frontDateAble = true;
//     }
//     vm.behindDateAble = false;

//     currentDate = new_time;
//     vm.showDateString = getShowDateString(new_time);
//   }
//   vm.nextHourDisabled = false;
//   return " " + (hour < 10 ? "0" + hour : hour) + ":00";
// }
// function getNextHour (time) {
//   console.log(currentDate.getFullYear() + "-" + vm.showDateString + "" + time);
//   let new_time = new Date(
//     currentDate.getFullYear() + "-" + vm.showDateString + "" + time
//   );
//   console.log(new Date());
//   let hour = new_time.getHours() + 3;
//   if (vm.behindDateAble && hour == 21) {
//     vm.nextHourDisabled = true;
//   }
//   if (hour == 24) {
//     hour = hour - 24;
//     new_time.setDate(new_time.getDate() + 1); //日期加1
//     dateChangedCounter -= 1; //计数器加1
//     if (dateChangedCounter <= 0) {
//       vm.behindDateAble = true;
//     }
//     vm.frontDateAble = false;
//     currentDate = new_time;
//     vm.showDateString = getShowDateString(new_time);
//   }
//   vm.lastHourDisabled = false;

//   return " " + (hour < 10 ? "0" + hour : hour) + ":00";
// }
//获得给定值对应色带的颜色 色带事先规定好
function getRGBFromRibbon (value, min, max) {
  // 当前数值 数组中的最小值和最大值
  let range = max - min; //滤波后的数组的最大最小值
  let red = 1,
    green = 1,
    blue = 1;
  if (value < min + 0.25 * range) {
    red = 0;
    green = (4 * (value - min)) / range;
  } else if (value < min + 0.5 * range) {
    red = 0;
    blue = 1 + (4 * (min + 0.25 * range - value)) / range;
  } else if (value < min + 0.75 * range) {
    red = (4 * (value - min - 0.5 * range)) / range;
    blue = 0;
  } else {
    green = 1 + (4 * (min + 0.75 * range - value)) / range;
    blue = 0;
  }
  return { red: red * 255, green: green * 255, blue: blue * 255 };
}
//获得气体排放量对应的颜色
function getGasEmissionColor (value, min, max) {
  // 当前数值 数组中的最小值和最大值
  let range = max - min;
  let red = 1,
    green = 1,
    blue = 1;
  if (value <= 0) {
    red = 0;
    // green = 4 * (value - min) / range
    green = 0;
    blue = 1;
    // }else if (value <= 100) {
    //   red = 0
    //   blue = (100 - value) /  100
    // }else if (value <= 1000) {
    //   red = 4 * (value - min - 0.5 * range) / range
    //   blue = 0
    // }else {
    //   green = 1 + 4 * (min + 0.75 * range - value) / range
    //   blue = 0
    // }
  } else if (value < 0.3 * range) {
    red = (103 + ((214 - 103) * (value + min)) / (0.3 * range)) / 255;
    blue = 24 / 255;
    green = 208 / 255;
  } else {
    // red = 1,  blue = 1, green= 0.5*(max-value)/range
    // red=0.6*(value-min)/range
    red = 214 / 255;
    blue = 24 / 255;
    green =
      (208 - ((208 - 99) * (value - 0.3 * range + min)) / (0.7 * range)) / 255;
    // green=148
  }

  return { red: red * 255, green: green * 255, blue: blue * 255 };
}
</script>
<style scoped>
::v-deep .leaflet-control-scale {
  position: absolute;
  right: 349px;
  bottom: 0;
  margin-bottom: 0;
  margin-right: 0;
}

::v-deep .leaflet-pane {
  z-index: 0;
}

/* ::v-deep .leaflet-top {
  z-index: 0;
} */

::v-deep .leaflet-popup-content {
  margin: 6px;
}

.main-button {
  position: absolute;
  right: 11px;
  top: 13px;
}

/* .popup-card-color {
  background-color: #000337 !important;
  border: solid 1px #000337;
  box-shadow: inset 0px 0px 500px 0px rgba(169, 181, 187, 0.3) !important;
} */

.opt-fire-btn {
  position: absolute;
  right: 11px;
  bottom: 135px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

/* .opt-fire-btn .v-btn:not(.v-btn--round).v-size--default {
  width: 30px;
  min-width: 30px;
} */
.opt-wind-btn {
  position: absolute;
  right: 11px;
  bottom: 285px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
/* .opt-wind-btn .v-btn:not(.v-btn--round).v-size--default {
  width: 30px;
  min-width: 30px;
} */
.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
  background: white;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

::v-deep .leaflet-control-layers {
  top: 60px;
  font-size: 14px;
}

.play-btn {
  position: absolute;
  bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  border-radius: 34px;
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
  background: white;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  max-width: 350px;
  height: 90px;
}

.opt-air-btn {
  position: absolute;
  right: 11px;
  bottom: 235px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

/* .opt-air-btn .v-btn:not(.v-btn--round).v-size--default {
  width: 30px;
  min-width: 30px;
} */

.color-scale {
  list-style: none;
  padding: 0 0 1.25rem;
  margin: 0;
}

.color-scale_item {
  float: left;
  line-height: 1rem;
}

.color-scale_value {
  position: relative;
  bottom: -1rem;
  font-size: 0.75rem;
}

.air-legend-card {
  position: absolute;
  right: 58px;
  bottom: 70px;
}

.progress-circular {
  position: absolute;
  left: 50%;
  top: 50%;
}

.aqi-quality-index-li {
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 14px;
}

.number-circle {
  border-radius: 10%;
  width: 30px;
  padding: 6px;
  color: white;
  text-align: center;
  font: 10px Arial, sans-serif;
}

.dashed-circle {
  height: 16px;
  width: 16px;
  border: 2px dashed #000;
  border-radius: 50%;
  /* display: inline-block; */
}

.solid-circle {
  height: 16px;
  width: 16px;
  border: 2px solid #000;
  border-radius: 50%;
  /* display: inline-block; */
}

.opt-greenhouse-gas-btn {
  position: absolute;
  right: 11px;
  bottom: 185px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

/* .opt-co2-btn .v-btn:not(.v-btn--round).v-size--default {
  width: 30px;
  min-width: 30px;
} */

.co2-legend-card {
  position: absolute;
  left: 10px;
  bottom: 25px;
}

.co2-color-gradients {
  height: 160px;
  width: 20px;
  float: left;
  background-color: black; /* For browsers that do not support gradients */
  background-image: linear-gradient(
    rgb(255, 0, 0),
    rgb(255, 255, 0),
    rgb(0, 255, 0),
    rgb(0, 255, 255),
    rgb(0, 0, 255)
  );
}

.co2-emission-color {
  height: 160px;
  width: 20px;
  background-color: black; /* For browsers that do not support gradients */
  background-image: linear-gradient(
    rgb(220, 30, 24),
    rgb(214, 208, 24),
    rgb(103, 208, 24)
  );
}

.about-btn {
  position: absolute;
  right: 11px;
  top: 130px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.about-btn .v-btn:not(.v-btn--round).v-size--default {
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.about-card {
  position: absolute;
  right: 65px;
  top: 130px;
}

.otp-btn-size {
  width: 30px !important;
  min-width: 30px !important;
}

/* ::v-deep .leaflet-control-layers-toggle:after{ 
    content:"your text"; 
    color:#000 ;
}
::v-deep .leaflet-control-layers-toggle{ 
    width:auto;
    background-position:3px 50% ;
    padding:3px;
    padding-left:36px;
    text-decoration:none;
    line-height:36px;

} */
</style>
